import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { FakeDbService } from "@fake-db/fake-db.service";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";
import { ContextMenuModule } from "@ctrl/ngx-rightclick";

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";
import { CardSnippetModule } from "@core/components/card-snippet/card-snippet.module";

import { coreConfig } from "app/app-config";
import { AuthGuard } from "app/auth/helpers/auth.guards";
import { fakeBackendProvider } from "app/auth/helpers"; // used to create fake backend
import { JwtInterceptor, ErrorInterceptor } from "app/auth/helpers";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { ContentHeaderModule } from "app/layout/components/content-header/content-header.module";

import { ContextMenuComponent } from "app/main/extensions/context-menu/context-menu.component";
import { AnimatedCustomContextMenuComponent } from "./main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component";
import { BasicCustomContextMenuComponent } from "./main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component";
import { SubMenuCustomContextMenuComponent } from "./main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component";
import { ToastsModule } from "./main/components/toasts/toasts.module";
import { ToastContainerComponent } from "./main/components/toasts/toast-container/toast-container.component";

const appRoutes: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("./main/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "apps",
    loadChildren: () =>
      import("./main/apps/apps.module").then((m) => m.AppsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "ui",
    loadChildren: () => import("./main/ui/ui.module").then((m) => m.UIModule),
    canActivate: [AuthGuard],
  },
  {
    path: "components",
    loadChildren: () =>
      import("./main/components/components.module").then(
        (m) => m.ComponentsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "extensions",
    loadChildren: () =>
      import("./main/extensions/extensions.module").then(
        (m) => m.ExtensionsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "forms",
    loadChildren: () =>
      import("./main/forms/forms.module").then((m) => m.FormsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "tables",
    loadChildren: () =>
      import("./main/tables/tables.module").then((m) => m.TablesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "charts-and-maps",
    loadChildren: () =>
      import("./main/charts-and-maps/charts-and-maps.module").then(
        (m) => m.ChartsAndMapsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./main/profile/profile.module").then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "mobile-load",
    loadChildren: () =>
      import("./main/mobileLoad/mobile-load.module").then(
        (m) => m.MobileLoadModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "bill-payment",
    loadChildren: () =>
      import("./main/billPayment/bill-payment.module").then(
        (m) => m.BillPaymentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "transaction-history",
    loadChildren: () =>
      import("./main/transactionHistory/transaction-history.module").then(
        (m) => m.BillPaymentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "balance-transfer",
    loadChildren: () =>
      import("./main/balanceTransfer/balance-transfer.module").then(
        (m) => m.BalanceTransferModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: "e-vouchers",
    loadChildren: () =>
      import("./main/E-Vouchers/e-voucher.module").then(
        (m) => m.EVoucherModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "ticketing",
    loadChildren: () =>
      import("./main/ticketing/ticketing.module").then(
        (m) => m.TicketingModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "bulk-payment",
    loadChildren: () =>
      import("./main/bulkPayment/bulk-payment.module").then(
        (m) => m.BulkPaymentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "change_password",
    loadChildren: () =>
      import("./main/changePassword/change_password.module").then(
        (m) => m.ChangePasswordModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "create-wallet",
    loadChildren: () =>
      import("./main/createWallet/createWallet.module").then(
        (m) => m.CreateWalletModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "wallet-type",
    loadChildren: () =>
      import("./main/wallet-type/wallet-type.module").then(
        (m) => m.WalletTypeModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "mobile",
    loadChildren: () =>
      import("./main/mobile/mobile.module").then((m) => m.MobileModule),
    canActivate: [AuthGuard],
  },
  {
    path: "deposit",
    loadChildren: () =>
      import("./main/deposit/deposit.module").then((m) => m.DepositModule),
    canActivate: [AuthGuard],
  },
  {
    path: "withdrawal",
    loadChildren: () =>
      import("./main/withdrawal/withdrawal.module").then(
        (m) => m.WithDrawalModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "agents",
    loadChildren: () =>
      import("./main/agents/agents.module").then((m) => m.AgentsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "sales",
    loadChildren: () =>
      import("./main/sales/sales.module").then((m) => m.SalesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "agent-sale-summary",
    loadChildren: () =>
      import("./main/agentSaleSummary/agent-sale-summary.module").then(
        (m) => m.AgentSaleSummaryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "banks",
    loadChildren: () =>
      import("./main/banks/banks.module").then((m) => m.BanksModule),
    canActivate: [AuthGuard],
  },
  {
    path: "cashin-payments",
    loadChildren: () =>
      import("./main/cashin-payments/cashin-payments.module").then(
        (m) => m.CashInPaymentsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "cashin-primary-wallet",
    loadChildren: () =>
      import("./main/cashin-primary-wallet/cashin-primary-wallet.module").then(
        (m) => m.CashInPrimaryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "cashin-secondary-wallet",
    loadChildren: () =>
      import(
        "./main/cashin-secondary-wallet/cashin-secondary-wallet.module"
      ).then((m) => m.CashInSecondaryModule),
    canActivate: [AuthGuard],
  },
  {
    path: "fingerprint-sensor",
    loadChildren: () =>
      import("./main/fingerprint-sensor/fingerprint-sensor.module").then(
        (m) => m.FingerPrintModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "user-profile",
    loadChildren: () =>
      import("./main/user-profile/user-profile.module").then(
        (m) => m.UserProfileModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "",
    redirectTo: "/dashboard/ecommerce",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/pages/miscellaneous/error", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [
    AppComponent,
    ContextMenuComponent,
    BasicCustomContextMenuComponent,
    AnimatedCustomContextMenuComponent,
    SubMenuCustomContextMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true,
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
    NgbModule,
    ToastsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    fakeBackendProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
