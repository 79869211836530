<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic & Translucent Toast -->
    <section>
      <div class="row match-height">
        <div class="col-md-6 col-sm-12">
          <core-card-snippet [snippetCode]="_snippetCodeBasic">
            <h4 class="card-title">Basic Toast</h4>

            <div class="card-body">
              <p class="card-text">
                Toasts provide feedback messages as notifications to the user.
                Toasts are as flexible as you need and have very little required
                markup. At a minimum, we require a single element to contain
                your “toasted” content and strongly encourage a dismiss button.
              </p>
              <button
                class="btn btn-outline-primary"
                (click)="toastBasic('I am a Basic toast', 2000)"
                rippleEffect
              >
                Toast
              </button>
            </div>
          </core-card-snippet>
        </div>
        <div class="col-md-6 col-sm-12">
          <core-card-snippet [snippetCode]="_snippetCodeTranslucent">
            <h4 class="card-title">Translucent</h4>
            <p class="card-text m-1">
              Toasts are slightly translucent, too, so they blend over whatever
              they might appear over. For browsers that support the
              backdrop-filter CSS property, we’ll also attempt to blur the
              elements under a toast.
            </p>

            <div class="card-body bg-primary p-2">
              <ngb-toast [autohide]="false">
                <ng-template ngbToastHeader>
                  <img
                    src="assets/images/logo/logo.png"
                    height="18"
                    width="25"
                    class="mr-2"
                    alt="Toast image"
                  />
                  <strong class="mr-auto toast-title">Vuexy Admin</strong>
                  <small class="text-muted toast-time ml-5 mr-1 pl-3"
                    >11 mins ago</small
                  >
                </ng-template>
                Hello, world! This is a toast message.
              </ngb-toast>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>
    <!--/ Basic & Translucent Toast -->

    <!-- Stacking & Placement Toast -->
    <section>
      <div class="row match-height">
        <div class="col-md-6 col-sm-12">
          <core-card-snippet [snippetCode]="_snippetCodeStacking">
            <h4 class="card-title">Stacking</h4>

            <div class="card-body">
              <p class="card-text mb-0">
                When you have multiple toasts, By default toasts vertically
                stack them in a readable manner.
              </p>
              <div class="demo-inline-spacing">
                <button
                  class="btn btn-outline-primary"
                  (click)="toastAutoHide()"
                  rippleEffect
                >
                  Auto Hide: {{ hideValue }}
                </button>
                <button
                  class="btn btn-outline-primary"
                  (click)="toastStacking()"
                  rippleEffect
                >
                  Toast
                </button>
              </div>
            </div>
          </core-card-snippet>
        </div>
        <div class="col-md-6 col-sm-12">
          <core-card-snippet [snippetCode]="_snippetCodePlacement">
            <h4 class="card-title">Placement</h4>

            <div class="card-body">
              <p class="card-text">
                You can place toasts with custom CSS as you need them, Ex: use
                <code>[ngStyle]="&#123; left: 0, right: 'unset' &#125;"</code>
                to place toasts on left side of the screen.
              </p>
              <button
                class="btn btn-outline-primary"
                (click)="toastPlacement()"
                rippleEffect
              >
                Toast Placement
              </button>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>
    <!--/ Stacking & Placement Toast -->

    <!-- app-toast-container component -->
  </div>
</div>
