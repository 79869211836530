<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>

    <!-- Menu Toggler -->
    <li class="nav-item nav-toggle">
      <a
        class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
        (click)="toggleSidebarCollapsible()"
      >
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a
        class="nav-link modern-nav-toggle d-block d-xl-none pr-0"
        (click)="toggleSidebar()"
      >
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li>
    <!-- Logout button -->
    <li ngbDropdown class="nav-item nav-toggle">
      <a
        class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
        (click)="handleConfirmation(modalWarning)"
        id="dropdown-user"
        ngbDropdownToggle
        id="dropdownMenuButton"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i
          [ngClass]="'icon-power'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownMenuButton"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem (click)="handleConfirmation(modalWarning)"
          ><span data-feather="'power'" [class]="'mr-50'"></span> Logout</a
        >
      </div>
      <a
        class="nav-link modern-nav-toggle d-block d-xl-none pr-0"
        (click)="handleConfirmation(modalWarning)"
      >
        <i
          data-feather="power"
          class="font-medium-2 text-primary toggle-icon"
        ></i>
      </a>
    </li>

    <!-- <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="logout()">
        <i [ngClass]="'icon-power'" class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li> -->
    <!-- <li class="nav-item nav-toggle">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pb-1" (click)="logout()">
        <i data-feather="power" class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li> -->
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
</div>
<!--/ Main menu -->

<!-- Modal -->
<ng-template #modalWarning let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel160">Confirmation</h5>
    <button
      type="button"
      class="close"
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    Are you sure you want to logout?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-warning"
      (click)="logout()"
      (click)="modal.dismiss('Cross click')"
      rippleEffect
    >
      Logout
    </button>
  </div>
</ng-template>
<!-- / Modal -->
