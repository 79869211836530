<!-- ngb-toast component -->
<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="toast.autohide"
  [delay]="toast.delay || 5000"
  (hide)="toastService.remove(toast)"
>
  <!-- toast header -->
  <ng-template ngbToastHeader>
    <!-- <img
      src="assets/images/logo/logo.png"
      class="mr-2"
      height="18"
      width="25"
      alt="Toast image"
    /> -->
    <div style="display: flex; align-items: center; gap: 0.5rem">
      <div
        class="data-feather-wrapper"
        [style.color]="
          toast.type == 'success'
            ? 'rgb(40, 199, 111)'
            : toast.type == 'warning'
            ? 'rgb(255, 159, 67)'
            : toast.type == 'info'
            ? 'rgb(0, 207, 232)'
            : 'rgb(234, 84, 85)'
        "
      >
        <span
          [data-feather]="
            toast.type == 'success'
              ? 'check'
              : toast.type == 'warning'
              ? 'alert-triangle'
              : toast.type == 'info'
              ? 'alert-circle'
              : 'x'
          "
        ></span>
      </div>
      <strong class="mr-auto toast-title">{{
        toast.type == "success"
          ? "Success"
          : toast.type == "warning"
          ? "Warning"
          : toast.type == "info"
          ? "Info"
          : "Error" ?? "Toast"
      }}</strong>
    </div>

    <small
      class="text-muted toast-time ml-5 mr-1 pl-3"
      style="visibility: hidden"
      >11 mins ago</small
    >
  </ng-template>
  <!--/ toast header -->

  <!-- toast body -->
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>
  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  <!--/ toast body -->
</ngb-toast>
<!--/ ngb-toast component -->
