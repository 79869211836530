import { Role } from './role';

export class User {
  id: number;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  avatar: string;
  role: Role;
  token?: string;
  passwordReset?: any;
  accountStatus?: any;
  accountId?: any;
  kycStatus?: any;
  fullName?: any;
  cellPhone?: any;
  profilePicture?: any;
  error?: any;
  access_token?: any;
  refresh_token?: any;
  expires_in?: any;
  token_type?: any;
  scope?: any;
  error_description?: any
}
