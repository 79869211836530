import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ToastService } from "app/main/components/toasts/toasts.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastService: ToastService,
    private readonly _router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Client
    );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    let deviceId = localStorage.getItem("deviceId");
    // let deviceId = "c5b3c162-80fe-4ea7-bd56-e31116493cfc"; // Usman bhai
    // let deviceId = "e95695f9-9b11-4673-ba45-351589c59c40"; // My phone
    return (
      this._http
        // .post<any>(`${environment.apiUrl}auth/authentication/signin?password=${password}&username=${email}&deviceId=ffeca6d2-169e-4a39-8c51-a6c79ed1cb11&application=CBA`, { email, password })
        // .post<any>(`${environment.apiUrl}auth/authentication/signin?password=${password}&username=${email}&deviceId=cd485e47-a673-4fc4-9503-8de526fb7730&application=CBA`, { email, password })
        // .post<any>(
        //   `${environment.apiUrl}auth/authentication/signin?password=${password}&username=${email}&deviceId=790292ea-d9da-46cf-85f5-464cc8c6a86f&application=CBA`,
        //   { email, password }
        // )
        // .post<any>(
        //   `${environment.apiUrl}auth/authentication/signin?password=${password}&username=${email}&deviceId=167a09fe-35a3-40fe-b2de-e7e2289ca9ef&application=CBA`,
        //   { email, password }
        // )
        .post<any>(`${environment.apiUrl}auth/authentication/signin`, {
          deviceId,
          application: "CBA",
          username: email,
          password,
        })
        .pipe(
          map((response) => {
            if (response?.additionalDetail == null) {
              if (response.code === 25006) {
                localStorage.setItem("accountId", response.returnId);
                localStorage.setItem("username", email);
                localStorage.setItem("password", password);
                localStorage.setItem("cellPhone", email);
                return response;
              } else if (response.code === -25) {
                localStorage.setItem("accountId", response.returnId);
                return response;
              } else {
                // this._toastrService.error(response?.message, "Login Failed", {
                //   toastClass: "toast ngx-toastr",
                //   closeButton: true,
                // });
                this._toastService.show(response?.message ?? "Login Failed", {
                  autohide: true,
                  type: "error",
                  delay: 2700,
                });
                return false;
              }
            }
            if (response?.additionalDetail.access_token == null) {
              // this._toastrService.error(
              //   response?.additionalDetail?.error_description,
              //   "Login Failed",
              //   { toastClass: "toast ngx-toastr", closeButton: true }
              // );
              this._toastService.show(
                response?.additionalDetail?.error_description ?? "Login Failed",
                {
                  autohide: true,
                  type: "error",
                  delay: 2700,
                }
              );
              return false;
            }
            if (response?.code == 25003) {
              // this._toastrService.error(
              //   response?.additionalDetail?.error_description ??
              //     "پاس ورڈ کی متعدد غلط کوششوں کی وجہ سے آپ کا اکاؤنٹ عارضی طور پر معطل کر دیا گیا ہے۔",
              //   "Login Failed",
              //   { toastClass: "toast ngx-toastr", closeButton: true }
              // );
              this._toastService.show(
                response?.additionalDetail?.error_description ??
                  "پاس ورڈ کی متعدد غلط کوششوں کی وجہ سے آپ کا اکاؤنٹ عارضی طور پر معطل کر دیا گیا ہے۔",
                {
                  autohide: true,
                  type: "error",
                  delay: 2700,
                }
              );
              return false;
            }

            setTimeout(() => {
              // this._toastrService.success(
              //   "You have successfully logged in as an ",
              //   "👋 Welcome !",
              //   { toastClass: "toast ngx-toastr", closeButton: true }
              // );
              this._toastService.show("You have successfully logged in", {
                autohide: true,
                type: "success",
                delay: 1500,
              });
            }, 2500);
            localStorage.setItem(
              "currentUser",
              JSON.stringify(response.additionalDetail)
            );
            localStorage.setItem(
              "token",
              response.additionalDetail.access_token
            );
            this.currentUserSubject.next(response);
            return response;
          })
        )
    );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  register(fullName: string, cellPhone: number) {
    let deviceId = localStorage.getItem("deviceId");
    return this._http
      .post<any>(`${environment.apiUrl}zingpay/unsecured/signup`, {
        fullName,
        cellPhone,
        deviceId: deviceId,
      })
      .pipe(
        map((response) => {
          if (
            response?.code &&
            response?.code < 5001 &&
            response?.code != 500
          ) {
            // this._toastrService.success(response?.message, "", {
            //   toastClass: "toast ngx-toastr",
            //   closeButton: true,
            // });
            this._toastService.show(response?.message ?? "", {
              autohide: true,
              type: "success",
              delay: 2700,
            });
            localStorage.setItem("accountId", response.returnId);
            localStorage.setItem("cellPhone", "" + cellPhone);
            return response.returnId;
          } else {
            // this._toastrService.error(response?.message, "", {
            //   toastClass: "toast ngx-toastr",
            //   closeButton: true,
            // });
            this._toastService.show(response?.message ?? "", {
              autohide: true,
              type: "error",
              delay: 2700,
            });
            return false;
          }
        })
      );
  }

  verifyPin(verificationPin: string) {
    return this._http
      .put<any>(`${environment.apiUrl}zingpay/unsecured/activate`, {
        accountId: localStorage.getItem("accountId"),
        pin: verificationPin,
      })
      .pipe(
        map((response) => {
          if (response?.code && response?.code < 5001) {
            // this._toastrService.success(response?.message, "", {
            //   toastClass: "toast ngx-toastr",
            //   closeButton: true,
            // });
            this._toastService.show(response?.message ?? "", {
              autohide: true,
              type: "success",
              delay: 2700,
            });
            return response;
          } else {
            // this._toastrService.error(response?.message, "", {
            //   toastClass: "toast ngx-toastr",
            //   closeButton: true,
            // });
            this._toastService.show(response?.message ?? "", {
              autohide: true,
              type: "error",
              delay: 2700,
            });
            return false;
          }
        })
      );
  }

  loginVerifyPin(verificationPin: string) {
    return this._http
      .post<any>(
        `${environment.apiUrl}zingpay/unsecured/verify-otp-and-register-new-device`,
        {
          accountId: localStorage.getItem("accountId"),
          pin: verificationPin,
          deviceId: localStorage.getItem("deviceId"),
        }
      )
      .pipe(
        map((response) => {
          if (response.code < 5001) {
            // this._toastrService.success(response?.message, "", {
            //   toastClass: "toast ngx-toastr",
            //   closeButton: true,
            // });
            this._toastService.show(response?.message ?? "", {
              autohide: true,
              type: "success",
              delay: 2700,
            });
            return response;
          } else {
            // this._toastrService.error(response?.message, "", {
            //   toastClass: "toast ngx-toastr",
            //   closeButton: true,
            // });
            this._toastService.show(response?.message ?? "", {
              autohide: true,
              type: "error",
              delay: 2700,
            });
            return false;
          }
        })
      );
  }

  resendPin() {
    let cellPhone = localStorage.getItem("cellPhone");
    return this._http
      .get<any>(
        `${environment.apiUrl}zingpay/unsecured/resend?cellPhone=${cellPhone}`
      )
      .pipe(
        map((response) => {
          // this._toastrService.success(response.message, "SUCCESS", {
          //   toastClass: "toast ngx-toastr",
          //   closeButton: true,
          // });
          this._toastService.show(response?.message ?? "", {
            autohide: true,
            type: "success",
            delay: 2700,
          });
          return response.returnId;
        })
      );
  }

  setPassword(password: string) {
    let accountId = localStorage.getItem("accountId");
    return this._http
      .put<any>(`${environment.apiUrl}zingpay/unsecured/create-password`, {
        accountId,
        confirmPassword: password,
        password,
      })
      .pipe(
        map((response) => {
          if (response?.code && response?.code < 5001) {
            return response.returnId;
          } else {
            // this._toastrService.error(response?.message, "", {
            //   toastClass: "toast ngx-toastr",
            //   closeButton: true,
            // });
            this._toastService.show(response?.message ?? "", {
              autohide: true,
              type: "error",
              delay: 2700,
            });
            return false;
          }
        })
      );
  }

  getForgotPasswordDetail(mobile: string, cnic?: string) {
    return this._http
      .get<any>(
        `${environment.apiUrl}zingpay/unsecured/forget-password-cell-phone?cellPhone=${mobile}&cnic=${cnic}`
      )
      .pipe(
        map((response) => {
          if (response && response?.code == 5014) {
            return response;
          } else if (response && response?.code == -25) {
            localStorage.setItem("accountId", response?.returnId);
            localStorage.setItem("cellPhone", mobile);
            this._router.navigate(["/pages/authentication/verify-pin"]);
          } else {
            if (response?.code && response?.code < 5001) {
              localStorage.setItem("accountId", response?.returnId);
              localStorage.setItem("cellPhone", mobile);
              return response?.returnId;
            } else {
              console.log("response", response);
              // this._toastrService.error(response?.message, "", {
              //   toastClass: "toast ngx-toastr",
              //   closeButton: true,
              // });
              this._toastService.show(response?.message ?? "", {
                autohide: true,
                type: "error",
                delay: 2700,
              });
              return false;
            }
          }
        })
      );
  }

  verifyMobileOTP(verificationPin: string) {
    return this._http
      .put<any>(`${environment.apiUrl}zingpay/unsecured/validate`, {
        accountId: localStorage.getItem("accountId"),
        pin: verificationPin,
      })
      .pipe(
        map((response) => {
          if (response?.code && response?.code < 5001) {
            // this._toastrService.success(response?.message, "", {
            //   toastClass: "toast ngx-toastr",
            //   closeButton: true,
            // });
            this._toastService.show(response?.message ?? "", {
              autohide: true,
              type: "success",
              delay: 2700,
            });
            return response;
          } else {
            // this._toastrService.error(response?.message, "", {
            //   toastClass: "toast ngx-toastr",
            //   closeButton: true,
            // });
            this._toastService.show(response?.message ?? "", {
              autohide: true,
              type: "error",
              delay: 2700,
            });
            return false;
          }
        })
      );
  }

  resendMobileOTP() {
    let cellPhone = localStorage.getItem("cellPhone");
    return this._http
      .get<any>(
        `${environment.apiUrl}zingpay/unsecured/resend-sms?cellPhone=${cellPhone}&isForgetPassword=true`
      )
      .pipe(
        map((response) => {
          // this._toastrService.success(response.message, "SUCCESS", {
          //   toastClass: "toast ngx-toastr",
          //   closeButton: true,
          // });
          this._toastService.show(response?.message ?? "", {
            autohide: true,
            type: "success",
            delay: 2700,
          });
          return response.returnId;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    // notify
    this.currentUserSubject.next(null);
  }
}
